import axios, { AxiosResponse } from "axios";
import qs from "qs";
import { CONFIG } from "../config";
import { DEFAULT_TEST_CASE } from "../constant";

export type ClientSignature = {
  code: number;
  msg: string;
  result: {
    banned: boolean;
    captcha: boolean;
    flag: boolean;
    ip: string;
    uuid: string;
    captchaUrl: string;
  };
};

export type LoginFn = (payload: LoginParam) => Promise<
  AxiosResponse<
    {
      code: number;
      data: {
        accessPage: {
          access_account: string;
          access_member: string;
          access_payment: string;
          access_report: string;
          access_stock: string;
        };
        hasPasscode: boolean;
        token: string;
      };
      token: string;
    },
    { code: number }
  >
>;

export type LoginParam = {
  password: string;
  username: string;
  clientSignature: string;
  captcha: string;
};

export type UpdateSeamlessParam = {
  agentId: string;
  hookProfile: string;
  hookTransfer: string;
};

export type WebhookHealthCheckParam = {
  username: string;
  caseId: string;
  title: string;
};

const service = {
  clientSignature: async () => {
    const res = await axios.get<ClientSignature>(
      `${CONFIG.API_URL}/a/m/clientSignature`
    );
    return res.data;
  },
  login: (({ password, username, clientSignature, captcha }) => {
    return axios.post(`${CONFIG.API_URL}/a/m/authen`, {
      password,
      username,
      clientSignature,
      captcha,
    });
  }) as LoginFn,

  getProfile: async () => {
    const res = await axios.get(`${CONFIG.API_URL}/a/m/getProfile`);

    return res.data?.data;
  },

  createKey: () => {
    return axios.post(`${CONFIG.API_URL}/a/ws/api-key`);
  },

  getLogs: async (skip: number) => {
    const res = await axios.get(
      `${CONFIG.API_URL}/a/ws/getLogHook?skip=${skip}`
    );

    return res.data?.data;
  },

  getSeamless: async () => {
    const res = await axios.get(`${CONFIG.API_URL}/a/ws/getSeamless`);

    if (
      !res.data?.data?.testCases ||
      res.data?.data?.testCases?.length !== DEFAULT_TEST_CASE.length
    ) {
      return {
        ...res.data?.data,
        testCases: DEFAULT_TEST_CASE,
      };
    }
    return res.data?.data;
  },

  updateSeamless: async (payload: UpdateSeamlessParam) => {
    const res = await axios.post(
      `${CONFIG.API_URL}/a/ws/updateSeamless`,
      payload
    );

    return res.data;
  },

  webhookHealthCheck: async (payload: WebhookHealthCheckParam) => {
    const res = await axios.post(
      `${CONFIG.API_URL}/a/ws/webhook-health-check`,
      payload
    );

    return res.data;
  },

  getOverview: async ({
    startDate = "",
    endDate = "",
  }: {
    startDate: string;
    endDate: string;
  }) => {
    const res = await axios.get(
      `${CONFIG.API_URL}/report/overview?startDate=${startDate}&endDate=${endDate}`
    );

    return res.data;
  },

  getWinLose: async ({
    startDate = "",
    endDate = "",
    username = "",
  }: {
    startDate: string;
    endDate: string;
    username?: string;
  }) => {
    const queryString = qs.stringify({
      startDate,
      endDate,
      username,
    });

    const res = await axios.get(
      `${CONFIG.API_URL}/report/winLose?${queryString}`
    );

    return res.data;
  },

  getDownline: async ({
    parentFocusId,
    focusId,
  }: {
    parentFocusId: string;
    focusId: string;
  }) => {
    const res = await axios.get(
      `${CONFIG.API_URL}/a/m/donwline?parentFocusId=${parentFocusId}&focusId=${focusId}`
    );

    return res.data;
  },

  getTicket: async ({
    username,
    betId,
    refId,
    startDate,
    endDate,
    status = [],
    page = 1,
    limit = 50,
    dateField = "createDate",
  }: {
    username?: string;
    betId?: string;
    refId?: string;
    startDate?: string;
    endDate?: string;
    status?: string[];
    page?: number;
    limit?: number;
    dateField?: string;
  }) => {
    const queryString = qs.stringify({
      startDate,
      endDate,
      username,
      betId,
      refId,
      status,
      page,
      limit,
      dateField,
    });
    const res = await axios.get(
      `${CONFIG.API_URL}/report/ticket?${queryString}`
    );

    return res.data;
  },
  manualStatus: async (payload: {
    event: string;
    betId: string;
    matches?: { result: string }[];
  }) => {
    return axios.post(`${CONFIG.API_URL}/a/ws/manual-status`, payload);
  },
};

export default service;
