import {
  Input,
  Checkbox,
  Button,
  Select,
  Option,
} from "@material-tailwind/react";
import Datepicker from "react-tailwindcss-datepicker";
import { useForm, Controller } from "react-hook-form";

import TicketTable from "../../Component/TicketTable";
import useDatePicker from "../../hook/useDatePicker";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import service from "../../service";
import { memo, NamedExoticComponent, useState } from "react";
import Loading from "../../Component/Loading";
import Pagination from "../../Component/Pagination";

export type FooterProps = {
  betAmount: number;
  realbetAmount: number;
  tickets: number;
  winLose: number;
};

const Footer: NamedExoticComponent<FooterProps> = memo(
  ({ betAmount, realbetAmount, tickets, winLose }) => {
    return (
      <tr className="font-bold">
        <td colSpan={2}>Total</td>
        <td></td>
        <td>
          {realbetAmount &&
            Number(realbetAmount.toFixed(2)).toLocaleString("en-US")}
        </td>
        <td>
          {betAmount && Number(betAmount.toFixed(2)).toLocaleString("en-US")}
        </td>
        <td></td>
        <td>{winLose && Number(winLose.toFixed(2)).toLocaleString("en-US")}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    );
  }
);

const Ticket = () => {
  const [searchParams] = useSearchParams();
  const { register, handleSubmit, watch, formState, control } = useForm({
    defaultValues: {
      refId: searchParams.get("refId") || "",
      betId: searchParams.get("betId") || "",
      username: searchParams.get("username") || "",
      status: ["false", "false", "false", "done", "false"],
      dateField: searchParams.get("dateField") || "createDate",
    },
  });
  const watchStatus = watch("status");

  const [page, setPage] = useState(1);
  const [limit] = useState(50);

  const { value, handleChange } = useDatePicker(
    searchParams.get("startDate")
      ? {
          startDate: dayjs(searchParams.get("startDate"), "DD-MM-YYYY")
            .startOf("day")
            .toDate(),
          endDate: dayjs(searchParams.get("endDate"), "DD-MM-YYYY")
            .startOf("day")
            .toDate(),
        }
      : undefined
  );

  const [queryParam, setQueryParam] = useState({
    username: searchParams.get("username") || "",
    betId: searchParams.get("betId") || "",
    refId: searchParams.get("refId") || "",
    startDate: value.startDate.toISOString() || "",
    endDate: value.endDate.toISOString() || "",
    status: ["done"],
    dateField: searchParams.get("dateField") || "createDate",
  });

  const ticket = useQuery({
    queryFn: () =>
      service.getTicket({
        username: queryParam.username,
        betId: queryParam.betId,
        refId: queryParam.refId,
        startDate: queryParam.startDate,
        endDate: queryParam.endDate,
        status: queryParam.status,
        page,
        limit,
        dateField: queryParam.dateField,
      }),
    queryKey: [
      "ticket",
      queryParam.startDate,
      queryParam.endDate,
      queryParam.username,
      queryParam.betId,
      queryParam.refId,
      queryParam.status,
      page,
      limit,
      queryParam.dateField,
    ],
    enabled: formState.isSubmitted,
  });

  return (
    <div>
      <form
        className="grid gap-2 grid-cols-2 mb-5"
        onSubmit={handleSubmit((data) => {
          setPage(1);
          setQueryParam({
            refId: data.refId.trim(),
            username: data.username.trim(),
            betId: data.betId.trim(),
            startDate: value.startDate.toISOString() || "",
            endDate: value.endDate.toISOString() || "",
            status: data.status.filter((e) => e !== `false`).filter(Boolean),
            dateField: data.dateField,
          });
        })}
      >
        <div>
          <Input
            label="Ref ID"
            className="bg-white"
            crossOrigin={undefined}
            {...register("refId")}
          />
        </div>
        <div>
          <Input
            label="Bet ID"
            className="bg-white"
            crossOrigin={undefined}
            {...register("betId")}
          />
        </div>
        <div>
          <Input
            label="Username"
            className="bg-white"
            crossOrigin={undefined}
            {...register("username")}
          />
        </div>
        <div className="col-span-2">
          <Controller
            control={control}
            name="dateField"
            render={({ field: { onChange, ref, value } }) => (
              <Select
                label="Date field"
                className="bg-white"
                onChange={onChange}
                ref={ref}
                value={value}
              >
                <Option value="createDate">Create Date</Option>
                <Option value="settleDate">Settle Date</Option>
              </Select>
            )}
          />

          <Datepicker
            primaryColor="green"
            value={value}
            onChange={(value) => {
              handleChange(value);
            }}
            showShortcuts={true}
            separator="to"
            displayFormat={"DD-MM-YYYY"}
            maxDate={dayjs().endOf("month").toDate()}
          />
        </div>
        <div className="col-span-2">
          <Controller
            control={control}
            name="status.0"
            render={({ field: { onChange, ref, value } }) => (
              <Checkbox
                crossOrigin={undefined}
                color="blue"
                checked={watchStatus.includes("waiting")}
                label="waiting"
                value="waiting"
                ref={ref}
                onChange={(e) => {
                  if (value === "waiting") {
                    onChange(false);
                  } else {
                    onChange(e.target.value);
                  }
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="status.1"
            render={({ field: { onChange, ref, value } }) => (
              <Checkbox
                crossOrigin={undefined}
                color="blue"
                checked={watchStatus.includes("rejected")}
                label="rejected"
                value="rejected"
                ref={ref}
                onChange={(e) => {
                  if (value === "rejected") {
                    onChange(false);
                  } else {
                    onChange(e.target.value);
                  }
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="status.2"
            render={({ field: { onChange, ref, value } }) => (
              <Checkbox
                crossOrigin={undefined}
                color="blue"
                checked={watchStatus.includes("running")}
                label="running"
                value="running"
                ref={ref}
                onChange={(e) => {
                  if (value === "running") {
                    onChange(false);
                  } else {
                    onChange(e.target.value);
                  }
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="status.3"
            render={({ field: { onChange, ref, value } }) => (
              <Checkbox
                crossOrigin={undefined}
                color="blue"
                checked={watchStatus.includes("done")}
                label="done"
                value="done"
                ref={ref}
                onChange={(e) => {
                  if (value === "done") {
                    onChange(false);
                  } else {
                    onChange(e.target.value);
                  }
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="status.4"
            render={({ field: { onChange, ref, value } }) => (
              <Checkbox
                crossOrigin={undefined}
                color="blue"
                checked={watchStatus.includes("cancelled")}
                label="cancelled"
                value="cancelled"
                ref={ref}
                onChange={(e) => {
                  if (value === "cancelled") {
                    onChange(false);
                  } else {
                    onChange(e.target.value);
                  }
                }}
              />
            )}
          />
        </div>
        <div>
          <Button type="submit">Submit</Button>
        </div>
      </form>
      {formState.isSubmitted &&
        (ticket.isLoading ? (
          <Loading className="m-auto" />
        ) : (
          <>
            <Pagination
              page={page}
              limit={limit}
              total={ticket.data?.total?.count}
              onClick={(page) => {
                setPage(page);
              }}
            />
            <div className="w-full overflow-x-scroll">
              <TicketTable
                Footer={
                  <Footer
                    betAmount={ticket.data?.total?.betAmt || 0}
                    realbetAmount={ticket.data?.total?.realBetAmt || 0}
                    tickets={ticket.data?.total?.count || 0}
                    winLose={ticket.data?.total?.winLose || 0}
                  />
                }
                data={ticket.data?.data}
                context={{ page, limit }}
              />
            </div>
            <Pagination
              page={page}
              limit={limit}
              total={ticket.data?.total?.count}
              onClick={(page) => {
                setPage(page);
              }}
            />
          </>
        ))}
    </div>
  );
};

export default Ticket;
